<template>
  <div class="grid">
    <main>
      <section class="contact">
        <h1>THE POOL</h1>
        <address class="contact__info">
          <a href="mailto:info@thepool.ch">info@thepool.ch</a>
          <a href="tel:+41764459564">+41 76 445 95 64</a>
          <!-- <address class="contact__info">
          <p>Weberstrasse 3</p>
          <p>CH-8004 Zurich</p>
        </address> -->
          <a href="https://www.instagram.com/thepoolteam">@thepoolteam</a>
        </address>
      </section>
    </main>
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";

export default {
  name: "Contact",
  components: {
    Footer,
  },
};
</script>

<style lang="scss" scoped>
.grid {
  display: grid;
  grid-template-rows: auto min-content;
  height: 100vh;
}
main {
  display: flex;

  // height: 90vh;
  padding: 0 2rem;

  justify-content: flex-start;
  align-items: flex-end;

  color: $white;

  .contact {
    h1 {
      font-weight: bold;
      // margin-bottom: 1rem;
    }

    .contact__info {
      display: flex;
      flex-direction: column;

      margin-bottom: 1rem;
      a,
      p {
        // margin-bottom: 0.5rem;

        font-style: normal;
        text-decoration: none;
        text-transform: uppercase;
        color: $white;
      }
    }
  }
}
@media (min-width: $md) {
  main {
    text-align: left;
  }
}
</style>
