<template>
  <footer>
    <p>© THE POOL Communications Consulting{{ "\xa0" }}GmbH</p>
    <div class="identity">
      <p>Web/Identity</p>
      <img
        src="@/assets/logos/nobu_logo-white.svg"
        width="90"
        alt="Studio Nobu"
        class="onblue"
      />
      <img
        src="@/assets/logos/nobu_logo-black.svg"
        width="90"
        alt="Studio Nobu"
        class="ongreen"
      />
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style lang="scss">
footer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  box-sizing: border-box;
  width: 100vw;
  height: auto;
  padding: 0rem 2rem 0 2rem;
  margin: 2rem 0;
  // min-height: 10vh;

  color: $white;

  p {
    font-size: 16px;
  }

  .identity {
    margin-top: 1rem;
    display: flex;

    width: auto;

    p {
      margin-top: 7px;
    }

    img {
      margin-left: 5px;
    }
  }
}
@media (min-width: $md) {
  footer {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    padding: 0 2rem;

    text-align: left;

    .identity {
      margin-top: 0;
    }
  }
}
</style>
